import React from "react";
import Layout from "../../../components/Layout";

const SuccessPage = () => {
    return (
        <Layout>
            <section className="hero is-primary is-bold is-large">
                <div className="hero-body has-text-centered">
                    <h1 className="title">Success</h1>
                </div>
            </section>
        </Layout>
    );
};

export default SuccessPage;
